
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { requestAddSecond } from '@/apis/second'
import { ElMessage } from 'element-plus'
import InfoEditForm, { FormData } from './components/InfoEditForm.vue'
export default defineComponent({
  components: {
    InfoEditForm
  },
  setup() {
    const router = useRouter()
    const data = reactive({
      formData: {
        agent_name: '',
        mobile: '',
        password: ''
      },
      validSuccess: async(option: FormData, cb: () => void) => {
        await requestAddSecond(option).then((res) => {
          if (res?.code === 200) {
            ElMessage.success('保存成功')
            router.go(-1)
          } else {
            ElMessage.error(res?.msg)
          }
        }, (error) => {
          ElMessage.error(error)
        })
        cb()
      },
      handleReset() {
        router.go(-1)
      }
    })
    return { ...toRefs(data) }
  }
})
